<template>
    <v-container fluid tag="section">

        <div class="text-end">
            <v-btn outlined class="font-weight-bold me-2" :to="`/companies`">{{ $t('Cancel') }}</v-btn>
            <v-btn color="purple" class="font-weight-bold me-0" @click="onSubmit" :loading="loading" :disabled="loading">{{ $t('Save') }}</v-btn>
        </div>

        <v-row>
            <v-col cols="12">
                <v-card class="rounded-lg mt-2">
                    <v-card-text class="px-0 py-0">
                        <v-form>
                            <v-container>
                                <v-text-field v-model="item.name" :label="$t('Name')"></v-text-field>
                                <v-text-field v-model="item.balance" :label="$t('Balance')"></v-text-field>
                            </v-container>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import companyService from '@/services/companyService'

export default {
    data() {
        return {
            loading: false,
            item: {
                name: null,
                balance: null,
            }
        }
    },
    async created() {
        if (this.$route.params.id) {
            await this.getCompany(this.$route.params.id)
        }
    },
    methods: {
        async getCompany(id) {
            try {
                const result = await companyService.get(id)
                this.item = result.data
            } catch (error) {
                this.$dialog.message.error(error.message)
                this.$router.push('/companies')
            }
        },
        async onSubmit() {
            this.loading = true
            try {
                if (this.$route.params.id) {
                    await companyService.edit(this.$route.params.id, this.item)
                } else {
                    await companyService.add(this.item)
                }
                this.$dialog.message.success(this.$t('Success'))
                this.$router.push('/companies')
            } catch (error) {
                this.$dialog.message.error(error.message)
            }
            this.loading = false
        }
    },
}
</script>